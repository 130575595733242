import { GET_REPORT_USER } from '../graphql/query/getReportUsers'
import { useLazyQuery } from '@apollo/client'

export const useReport = () => {
  const [getReportUser, { loading: loadingGetReport }] = useLazyQuery(
    GET_REPORT_USER,
    {
      fetchPolicy: 'network-only',
      onError: (err) => {
        console.log(
          'onError get Report Users',
          err?.graphQLErrors[0]?.debugMessage
        )
      }
    }
  )
  const GetReportUser = async () => {
    try {
      const res = await getReportUser({ variables: {} })
      return res.data
    } catch (error) {
      console.log(error)
    }
  }
  return { GetReportUser, loadingGetReport }
}
