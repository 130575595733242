import DataTable from 'react-data-table-component'
import toast from 'react-hot-toast'
// import { useHistory } from 'react-router-dom'
import swal from 'sweetalert'
import { IconDelete } from '../../../assets/icons/icons'
import Button from '../../../components/Buttons/Button'
import Heading from '../../../components/Heading'
import Spinner from '../../../components/Spinner/Spinner'
import { useUserServices } from '../../../services/useUserServices'
import { customStyles, paginacionOpciones } from './assetsDataTable'
import { useReport } from '../../../services/useReport'

const Clientes = () => {
  // const history = useHistory()
  const { db: dbUsers, loadingGetData, deleteUser } = useUserServices()
  const { GetReportUser } = useReport()

  const CallQueryReport = async () => {
    const { GetReporteUsers } = await GetReportUser()
    const date = new Date()
    const currentDate =
      date.getDate() +
      '/' +
      date.getMonth() +
      '/' +
      date.getFullYear() +
      ' ' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ':' +
      date.getSeconds()
    const a = document.createElement('a')
    a.href = GetReporteUsers
    a.target = '_blank'
    // a.setAttribute(
    //   'href',
    //   'data:text/plain;charset=utf-8, ' + GetReporteUsers
    // )
    a.download = 'Reporte User ' + currentDate + '.xlsx'
    document.body.appendChild(a)
    a.click()
  }

  const columnas = [
    {
      name: 'ID',
      selector: (row) => row.userId,
      sortable: true,
      grow: 2,
      left: true
    },
    {
      name: 'Nombre',
      selector: (row) => row.nombre,
      sortable: true,
      grow: 2,
      left: true
    },
    {
      name: 'Apellido',
      selector: (row) => row.apellidos,
      sortable: true,
      grow: 2,
      left: true
    },
    {
      name: 'E-mail',
      selector: (row) => row.email,
      sortable: true,
      grow: 2,
      left: true
    },
    {
      name: 'Acciones',
      selector: (row) => (
        <button
          className="ml-7 cursor-pointer "
          onClick={() => {
            swal({
              title: `Desea eliminar el usuario ${row?.nombre}?`,
              text:
                'Se borraran todos los datos que esten asociados a este usuario',
              icon: 'warning',
              buttons: true,
              dangerMode: true
            }).then(async (rpta) => {
              if (rpta) {
                deleteUser({
                  id: row?.userId
                })
                toast.success('Usuario Eliminado!')
              }
            })
          }}
        >
          <IconDelete />
        </button>
      )
    }
  ]

  return (
    <div className="shadow md:rounded bg-white p-5 py-10 md:p-10 animate__fadeIn animate__animated">
      <div className="flex justify-between mb-5">
        <Heading>Clientes</Heading>
        <Button variant="primary" size="md" onClick={() => CallQueryReport()}>
          Generar Reporte
        </Button>
      </div>
      {/* eslint-disable  */}
      {loadingGetData ? (
        <Spinner />
      ) : (
        <div className="w-full mb-8 overflow-hidden rounded-md md:shadow-xl max-h-screen overflow-y-auto  ">
          <div className="w-full overflow-x-auto min-h-screen">
            <DataTable
              data={dbUsers}
              columns={columnas}
              pagination
              paginationComponentOptions={paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight="85vh"
              customStyles={customStyles}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Clientes
